<template>
  <div class="introdution-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      :numBers="numBers"
      @clickNext="nextPage"
      @updateNumbers="updateNumbers($event)"
    ></PageButton>
    <div class="introdution-content">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="numBers"
        @changeTheNumbers="changeTheNumbers"
      >
      </SceneSwitchThumbnails>

      <div
        class="customlesson-conter-picture-switching story-one"
        v-if="numBers === 1 || numBers === 2"
      >
        <div class="conter-picture">
          <img src="@/assets/img/16-GAMES/G01-hi/background-ball.svg" alt="" />
          <div class="conter-picture-right">
            <img
              src="@/assets/img/16-GAMES/G01-hi/lesson-01-astronaut.svg"
              alt=""
            />
            <div class="speech-bubble-1">
              <Speechbubble
                :question="`?`"
                :answer="[
                  { py: 'Nǐ', hz: '你' },
                  { py: 'hǎo', hz: '好' },
                ]"
                :size="`small`"
                :secondClickIncreaseNumbers="true"
                :firstClickIncreaseNumbers="true"
                @clickBubbleBackSide="numClick(numBers)"
                :key="1"
              ></Speechbubble>
            </div>
          </div>
        </div>
      </div>

      <div
        class="customlesson-conter-picture-switching story-two"
        v-if="numBers === 3 || numBers === 4 || numBers === 5"
      >
        <div class="conter-picture">
          <img src="@/assets/img/16-GAMES/G01-hi/background-ball.svg" alt="" />
          <div class="conter-picture-right">
            <img src="@/assets/img/16-GAMES/G01-hi/lesson-01-dog.svg" alt="" />
            <div class="speech-bubble-2">
              <Speechbubble
                :question="`?`"
                :answer="[
                  { py: 'Nǐ', hz: '你' },
                  { py: 'hǎo', hz: '好' },
                ]"
                :size="`small`"
                @clickBubbleBackSide="numClick(numBers)"
                :firstClickIncreaseNumbers="true"
                :secondClickIncreaseNumbers="true"
                :key="2"
              ></Speechbubble>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import Speechbubble from "@/components/Course/CoursePage/SpeechBubble/index.vue";
import { startConfetti, playCorrectSound } from "@/utils/tools";

export default {
  components: {
    SceneSwitchThumbnails,
    PageButton,
    Speechbubble,
  },
  data() {
    return {
      numBers: 1,
      isLastStep: false,
      success_audio_sound: require("@/assets/audio/correct_answer.mp3"),
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G01-hi/intro-astronaut.svg"),
          isCircle: true,
          startNumberRange: 1,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G01-hi/intro-dog.svg"),
          isCircle: true,
          startNumberRange: 3,
          endNumberRange: 4,
        },
      ],
      clickBubbleSocketInfo: {},
      clickMenuSocketInfo: {},
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
    };
  },
  watch: {
    // 发送
    clickBubbleSocketInfo: {
      handler(value) {
        // 测试socket
        const data = {
          clickType: 30001,
          data: { value },
          text: "introductoion页面按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickMenuSocketInfo: {
      handler(value) {
        // 测试socket
        console.log(value);
        const data = {
          clickType: 3000101,
          data: { value },
          text: "introductoion页面侧边菜单栏按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },

  mounted() {
    console.log("123");
    // 接收
    this.$bus.$on("clickIntroSteps", ({ type }) => {
      this.numClick(type, true);
    });
    this.$bus.$on("clickIntroAside", ({ index }) => {
      this.changeTheNumbers(index, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickIntroSteps");
    this.$bus.$off("clickIntroAside");
  },
  methods: {
    changeTheNumbers(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.numBers = index;
    },
    numClick(type, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickBubbleSocketInfo = {
          type,
          value: Math.random(),
        };
      }
      this.numBers = type + 1;
      if (this.numBers == 2) {
        playCorrectSound(true, false);
      } else if (this.numBers == 4) {
        playCorrectSound();
        startConfetti();
        this.isLastStep = true;
      } else {
        return;
      }
    },
    updateNumbers(value) {
      this.numBers = value;
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
  },
};
</script>
<style scoped lang="scss">
.txt-py {
  font-family: "STHeitiSCPinyin-Light";
}

.introdution-container {
  .introdution-content {
    width: 100%;
    height: 100%;
    background-image: url("../../../../../assets/img/03-Backgrounds/level-1-background-blue.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #edf2f9;
    border-radius: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    .customlesson-conter-picture-switching {
      width: 50%;
      .conter-picture {
        width: 80%;
        position: absolute;
        top: 50%;
        left: 15%;
        display: flex;
        @media (max-width: 1700px) {
          width: 60%;
          top: 55%;
        }
        @media screen and (max-height: 900px) {
          // ipad
          // top: 40%;
          img {
            max-height: 70vh;
          }
        }
        img {
          width: 35%;
        }
        .conter-picture-right {
          position: absolute;
          bottom: 10%;
          left: 10%;
          cursor: default;
          width: 80%;
          @media (max-width: 1700px) {
            width: 100%;
          }
          img {
            width: 100%;
          }
          .conter-picture-left,
          .speech-bubble-1,
          .speech-bubble-2 {
            width: auto;
            position: absolute;
            top: 6%;
            left: 50%;
            cursor: pointer;

            img {
              width: 100px;

              @media (max-width: 1700px) {
                width: 80px;
              }
            }
            .conter-picture-left-word {
              position: absolute;
              top: 10%;
              // left: 37%;
              text-align: center;
              @media (max-width: 1700px) {
                top: 6%;
              }

              span {
                font-size: 40px;
                color: #fff;

                &.question {
                  font-family: "GenSenRoundedTW";
                }
              }
            }
          }
          .speech-bubble-1 {
            top: 13%;

            @media screen and (max-width: 2200px) {
              // external screen
              top: 0%;
            }
          }

          .speech-bubble-2 {
            @media screen and (max-width: 2200px) {
              // external screen
              top: 5%;
            }
          }
          .customlessonOnt_tree {
            width: 25%;
            position: absolute;
            top: 10%;
            left: 50%;
            img {
              width: 100%;
            }

            .dialog-big {
            }
            .conter-picture-left-word {
              position: absolute;
              top: 9%;
              left: 0;
              right: 0;
              width: 100%;
              display: flex;
              flex-flow: column;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              margin-left: -5px;

              @media (max-width: 1700px) {
                line-height: 33px;
              }
              @media (max-width: 1300px) {
                //ipad
                top: 4%;
                line-height: 28px;
              }
              span {
                font-size: 30px;
                color: #fff;
              }
            }
          }
          .lesson_treeImg {
            width: 30%;
            position: absolute;
            top: -10%;
            right: 30%;
            img {
              width: 100%;
            }
          }
        }
      }
      &.story-one {
        .conter-picture-left {
          @media (max-width: 1700px) {
            top: 15%;
          }
        }

        .txt-py {
          // very large screen
          font-size: 45px !important;
          @media screen and (max-width: 2200px) {
            // external screen
            font-size: 30px !important;
          }
          @media screen and (max-width: 1500px) {
            // laptop
            font-size: 30px !important;
          }
          @media screen and (max-width: 1300px) {
            // ipad
            font-size: 15px !important;
          }
        }
        .txt-hz {
          // very large screen
          font-size: 50px !important;
          @media screen and (max-width: 2200px) {
            // external screen
            font-size: 35px !important;
          }
          @media screen and (max-width: 1500px) {
            // laptop
            font-size: 35px !important;
          }
          @media screen and (max-width: 1300px) {
            // ipad
            font-size: 20px !important;
          }
        }
        .txt-en {
          font-size: 60px;
          @media screen and (max-width: 2000px) {
            font-size: 40px;
          }
          @media screen and (max-width: 1300px) {
            font-size: 35px;
          }
        }
      }
      &.story-two {
        .conter-picture-left {
          top: 25% !important;
          @media (max-width: 1700px) {
            top: 25%;
          }
        }

        .txt-py {
          // very large screen
          font-size: 45px !important;
          @media screen and (max-width: 2200px) {
            // external screen
            font-size: 30px !important;
          }
          @media screen and (max-width: 1500px) {
            // laptop
            font-size: 30px !important;
          }
          @media screen and (max-width: 1300px) {
            // ipad
            font-size: 15px !important;
          }
        }
        .txt-hz {
          // very large screen
          font-size: 50px !important;
          @media screen and (max-width: 2200px) {
            // external screen
            font-size: 35px !important;
          }
          @media screen and (max-width: 1500px) {
            // laptop
            font-size: 35px !important;
          }
          @media screen and (max-width: 1300px) {
            // ipad
            font-size: 20px !important;
          }
        }
        .txt-en {
          font-size: 60px;
          @media screen and (max-width: 2000px) {
            font-size: 40px;
          }
          @media screen and (max-width: 1300px) {
            font-size: 35px;
          }
        }
      }
    }
  }
}

/* -------------------------------------------------------------------------- */
/*                        For not-too-height screens                       */
/* -------------------------------------------------------------------------- */
// very large screen

@media screen and (max-height: 2200px) {
  // external screen
}
@media screen and (max-height: 1500px) {
  // laptop
}
@media screen and (max-height: 500) {
  // ipad
}
</style>
