<template>
  <div class="practice-page-four-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="finger" @click="numClick()">
      <img src="@/assets/img/17-Tutorials/instructions.svg" alt="" />
    </div>
    <div class="practice-page-four-content">
      <div
        class="customlesson-picture-switching-right"
        v-if="numBers === 1 || numBers === 2"
      >
        <img
          v-if="numBers === 1"
          src="@/assets/img/16-GAMES/G04-photo-studio/photo-dongdong-filter.svg"
          alt=""
        />
        <img
          v-if="numBers === 2"
          src="@/assets/img/16-GAMES/G04-photo-studio/photo-dongdong.svg"
          alt=""
        />
      </div>
      <div
        class="customlesson-picture-switching-left"
        v-if="numBers === 3 || numBers === 4"
      >
        <img
          v-if="numBers === 3"
          src="@/assets/img/16-GAMES/G04-photo-studio/photo-xiaoai-filter.svg"
          alt=""
        />
        <img
          v-if="numBers === 4"
          src="@/assets/img/16-GAMES/G04-photo-studio/photo-xiaoai.svg"
          alt=""
        />
        <!-- <div class="customlesson-picture-text" v-if="numBers === 5">
          <span class="font-hanzi-medium">小 爱</span>
        </div> -->
      </div>

      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="numBers"
        @changeTheNumbers="changeTheNumbers"
      >
      </SceneSwitchThumbnails>
      <div class="customlesson-conter-right-text">
        <div class="right-text">
          <span class="font-text txt-py font-pinyin-medium">shì</span>
          <span class="txt-hz font-hanzi-medium">是.....</span>
        </div>
        <div class="camera">
          <img src="@/assets/img/16-GAMES/G04-photo-studio/camera.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
export default {
  components: {
    SceneSwitchThumbnails,
    PageButton,
  },
  created() {
    // this.userAvatar = this.$store.state.avatar.userAvatar;
    // this.teacherAvatar = this.$store.state.avatar.teacherAvatar;
    // this.buttonList[2] = {
    //   image: this.teacherAvatar,
    //   startNumberRange: 5,
    //   endNumberRange: 6,
    // };
    // this.buttonList[3] = {
    //   image: this.userAvatar,
    //   startNumberRange: 7,
    //   endNumberRange: 8,
    // };
    // console.log(this.userAvatar);
  },
  data() {
    return {
      userAvatar: "",
      teacherAvatar: "",

      numBers: 1, //start with the finder

      isLastStep: false,
      success_audio_sound: require("@/assets/audio/correct_answer.mp3"),
      wrong_audio_sound: require("@/assets/audio/wrong_answer.mp3"),

      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G04-photo-studio/menu-dongdong.svg"),
          startNumberRange: 1,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G04-photo-studio/menu-xiaoai.svg"),
          startNumberRange: 3,
          endNumberRange: 4,
        },
      ],
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 1,
            endNumberRange: 8,
          },
        ],
      },
      clickSocketInfo: {},
      clickAsideSocketInfo: {},
    };
  },
  // 发送
  watch: {
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 30005,
          data: { value },
          text: "practicePage页面按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
    },
    clickAsideSocketInfo: {
      handler(value) {
        const data = {
          clickType: 3000501,
          data: { value },
          text: "practicePage页面按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
    },
  },
  // 接收
  mounted() {
    this.$bus.$on("clickPractFourPage", () => {
      this.numClick("socket", true);
    });
    this.$bus.$on("clickPractFourPageMenu", ({ index }) => {
      this.changeTheNumbers(index, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickPractFourPage");
    this.$bus.$off("clickPractFourPageMenu");
  },
  methods: {
    changeTheNumbers(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickAsideSocketInfo = {
          index,
          value: Math.random(),
        };
      }

      this.numBers = index;
      if (index < 4) {
        this.isLastStep = false;
      }
    },
    numClick(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickSocketInfo = {
          value: Math.random(),
        };
      }
      this.numBers++;
      if ([2, 4].indexOf(this.numBers) > -1) {
        playCorrectSound(true, false);
      }
      if (this.numBers === 4) {
        this.isLastStep = true;
        playCorrectSound();
        startConfetti();
      }
      if (this.numBers > 4) {
        this.numBers = 4;
      }
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    updateNumbers(value) {
      this.numBers = value;
      console.log(value);
    },
  },
};
</script>
<style scoped lang="scss">
.practice-page-four-container {
  .practice-page-four-content {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-color: #f7f4e0;
    border-radius: 58px;
    position: relative;
    .customlesson-picture-switching-right {
      pointer-events: none;
      width: 98%;
      position: absolute;
      top: 0;
      left: 0;
      img {
        width: 70%;
      }
      .customlesson-picture-text {
        position: absolute;
        top: 77%;
        left: 50%;
        z-index: 12;
        transform: rotate(-7deg);
        span {
          color: #cd4c3f;
          font-size: 50px;
          font-family: "GenSenRoundedTW";
          display: inline-block;
        }
      }

      .txt-hz {
        cursor: default;
      }
      .teacher-avatar {
        position: absolute;
        top: 35%;
        width: 13%;
        height: 26%;
        left: 45%;
        border-radius: 50%;
      }
    }
    .customlesson-picture-switching-left {
      width: 98%;
      position: absolute;
      top: 0;
      left: 40%;
      img {
        width: 60%;
      }
      .customlesson-picture-text {
        position: absolute;
        top: 79%;
        left: 10%;
        z-index: 13;
        transform: rotate(7deg);
        span {
          color: #fff;
          font-size: 50px;
          font-family: "GenSenRoundedTW";
          display: inline-block;
        }
      }
      .txt-hz {
        cursor: default;
      }
      .user-avatar {
        position: absolute;
        top: 38%;
        width: 14%;
        height: 26%;
        left: 11%;
        border-radius: 50%;
      }
    }
    .customlesson-conter-right-text {
      width: 98%;
      height: 95%;
      .right-text {
        background-color: #cd4c3f;
        border-top-right-radius: 29px;
        border-bottom-right-radius: 29px;
        position: absolute;
        top: 6%;
        left: 0;
        display: flex;
        justify-content: center;
        flex-flow: column;
        z-index: 9;
        width: auto;
        height: auto;
        padding: 3%;

        span:nth-child(1) {
          color: #ffffff;
          font-size: 35px;
        }
        span:nth-child(2) {
          color: #ffffff;
          font-size: 50px;
          font-family: "GenSenRoundedTW";
        }
      }
      .camera {
        position: absolute;
        bottom: 0;
        left: 10%;
        width: 25%;
        img {
          width: 100%;
          // cursor: pointer;
        }
      }

      .txt-py {
        // very large screen
        padding-left: 2%;
        @media screen and (max-width: 2200px) {
          // external screen
        }
        @media screen and (max-width: 1500px) {
          // laptop
        }
        @media screen and (max-width: 1300px) {
          // ipad
          padding-left: 4%;
        }
      }
      .txt-py {
        cursor: default;
      }
      .txt-hz {
        // very large screen
        cursor: default;
      }
    }
  }
  .finger {
    position: absolute;
    bottom: 0;
    left: 22%;
    z-index: 10;
    width: 45%;
    cursor: pointer;
    img {
      width: 80%;
    }
  }
}
</style>
