<template>
  <div class="practice-page-three-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content">
      <Star :totalStarNumber="9" :currentIndex="starNo" />
      <!-- 主体内容 -->
      <div class="word-content">
        <div class="word-item-area">
          <div class="word-item-wo" @click.stop="checkClick(0)">
            <span class="pinyin font-pinyin-medium">wǒ</span>
            <span class="font-hanzi-medium">我</span>
          </div>
        </div>
        <div class="word-item-area">
          <div class="word-item-ni" @click.stop="checkClick(1)">
            <span class="pinyin font-pinyin-medium">nǐ</span>
            <span class="font-hanzi-medium">你</span>
          </div>
        </div>
      </div>
      <div class="select-results">
        <img
          class="bg-img"
          src="@/assets/img/16-GAMES/G03-mirror/mirror-background.svg"
          alt=""
        />
        <div class="mirror-img">
          <template v-for="(item, index) in mirrorList">
            <img
              :src="item.imgSrc"
              alt=""
              :key="index"
              v-show="currentImgIndex === index"
            />
          </template>
        </div>
        <div class="lesson-correct" v-if="rightGif === 1">
          <img src="@/assets/img/05-Panda/panda-correct.gif" alt="" />
        </div>
        <div class="lesson-error" v-if="rightGif === 0">
          <img src="@/assets/img/05-Panda/panda-wrong.gif" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import Star from "@/components/Course/GamePage/Star";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
export default {
  components: {
    PageButton,
    Star,
  },

  data() {
    // 0 我  1 你
    return {
      mirrorList: [
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/mirror-1-ni.svg"),
          status: 1,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/mirror-2-wo.svg"),
          status: 0,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/mirror-3-ni.svg"),
          status: 1,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/mirror-4-wo.svg"),
          status: 0,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/mirror-5-ni.svg"),
          status: 1,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/mirror-6-wo.svg"),
          status: 0,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/mirror-7-wo.svg"),
          status: 0,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/mirror-8-ni.svg"),
          status: 1,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/mirror-9-wo.svg"),
          status: 0,
        },
      ],
      currentImgIndex: 0,
      rightGif: null,
      isLastStep: false,
      starNo: 0,
      checkSocketInfo: {},

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
    };
  },
  watch: {
    checkSocketInfo: {
      handler(value) {
        const data = {
          clickType: 3000401,
          data: { value },
          text: "practicePageThree页面按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("changeMirrorImgSocket", ({ item }) => {
      this.checkClick(item, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("changeMirrorImgSocket");
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    checkClick(item, isFromSocket = false) {
      if (!isFromSocket) {
        this.checkSocketInfo = {
          value: Math.random(),
          item,
        };
      }
      if (this.mirrorList[this.currentImgIndex].status === item) {
        playCorrectSound(true, false);
        this.rightGif = 1;
        setTimeout(() => {
          this.currentImgIndex++;
          this.starNo++;

          if (this.currentImgIndex >= this.mirrorList.length) {
            this.isLastStep = true;
            this.currentImgIndex = this.mirrorList.length - 1;
            playCorrectSound();
            startConfetti();
          }
        }, 1000);
      } else {
        playCorrectSound(false);
        this.rightGif = 0;
      }
      setTimeout(() => {
        this.rightGif = null;
      }, 1000);
    },
  },
};
</script>
<style scoped lang="scss">
.practice-page-three-container {
  .game-content {
    width: 100%;
    height: 100%;
    background-color: #f7f4e0;
    border-radius: 58px;
    overflow: hidden;
    position: relative;
    .customlesson-breakthrough-part {
      position: absolute;
      top: 0%;
      right: 10%;
      height: 40px;
      display: flex;
      padding: 10px 30px;
      background-color: #f4d737;
      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
      border-bottom: 6px solid #d1b726;
      z-index: 1001;
      .demo {
        display: flex;
        .customlesson-breakthrough-part-book {
          margin-left: 5px;
          img {
            width: 25px;
            height: 35px;
          }
        }
      }
    }
    .word-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .word-item-area {
        width: 50%;
        height: 90%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: 40%;
        .word-item-ni {
          min-width: 110px;
          min-height: 120px;
          z-index: 1;
          border-radius: 15px;
          background-color: #224e96;
          border-bottom: 8px solid #143160;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          span {
            color: #fff;
            font-size: 25px;
          }
          span:nth-child(2) {
            color: #fff;
            font-size: 30px;
          }
        }
        .word-item-ni:hover {
          z-index: 1;
          border-radius: 15px;
          background-color: #173d79;
          border-top: 8px solid #143160;
          border-bottom: 0px;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          span {
            color: #fff;
            font-size: 25px;
          }
          span:nth-child(2) {
            color: #fff;
            font-size: 30px;
          }
        }
        .word-item-wo {
          min-width: 110px;
          min-height: 120px;
          z-index: 1;
          border-radius: 15px;
          background-color: #cd4c3f;
          border-bottom: 8px solid #ac3d32;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          span {
            color: #fff;
            font-size: 25px;
          }
          span:nth-child(2) {
            color: #fff;
            font-size: 30px;
          }
        }
        .word-item-wo:hover {
          border-radius: 15px;
          background-color: #912016;
          border-top: 8px solid #6e0c03;
          border-bottom: 0px;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          span {
            color: #fff;
            font-size: 25px;
          }
          span:nth-child(2) {
            color: #fff;
            font-size: 30px;
          }
        }
      }
    }

    .select-results {
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .bg-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .lesson-error {
        width: 35%;
        position: absolute;
        bottom: -1%;
        left: 10%;
        z-index: 5;
        img {
          width: 100%;
        }
      }
      .lesson-correct {
        width: 30%;
        position: absolute;
        bottom: -1%;
        left: 10%;
        z-index: 5;
        img {
          width: 100%;
        }
      }
      .mirror-img {
        width: 25%;
        // height: 30%;
        position: absolute;
        top: 11%;

        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
