<template>
  <!-- 

      size: small | medium | large
 -->
  <div>
    <div
      class="heartbeat_animation hover-opacity-soft dialog-bubble-speech"
      :class="{
        size_small: size == 'small',
        size_medium: size == 'medium',
        size_large: size == 'large',
      }"
      @click="clickBubble()"
    >
      <img
        :src="
          require(`@/assets/img/16-GAMES/G01-hi/bubble-${
            direction == 'right' ? 'right-' : ''
          }red-${size}.svg`)
        "
        alt=""
        class="dialog-bubble__img"
      />
      <div
        class="
          conter-picture-left-word
          hover-opacity-soft
          dialog-bubble-speech__content
        "
      >
        <!-- {{          question (multiple)        }} -->
        <span
          class="question question_multiple"
          :class="{ isHidden: showBack }"
          v-if="Array.isArray(question) && question.length > 0"
        >
          <span
            v-for="(item, index) in question"
            :key="index"
            class="wordWrapper"
          >
            <span
              v-if="item.type !== 'newline'"
              class="txt-py font-pinyin-medium"
              >{{ item.py }}</span
            >
            <span
              v-if="item.type !== 'newline'"
              class="txt-hz font-hanzi-medium"
              >{{ item.hz }}</span
            >
            <span v-if="item.type == 'newline'" class="newline"><br /></span>
          </span>
        </span>
        <!-- {{          question (single)        }} -->
        <span
          class="wordWrapper question question_single font-hanzi-medium"
          :class="{ isHidden: showBack }"
          v-else-if="!Array.isArray(question)"
        >
          {{ question }}
        </span>
        <!-- {{          answer        }} -->

        <span
          ref="answerSel"
          :class="{ isHidden: !showBack }"
          class="fadein answer font-face"
        >
          <span
            v-for="(item, index) in answer"
            :key="index"
            class="wordWrapper"
          >
            <span
              v-if="item.type !== 'newline'"
              class="txt-py font-pinyin-medium"
              >{{ item.py }}</span
            >
            <span
              v-if="item.type !== 'newline'"
              class="txt-hz font-hanzi-medium"
              >{{ item.hz }}</span
            >
            <span v-if="item.type == 'newline'" class="newline"><br /></span>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      require: true,
    },
    direction: String,
    answer: Array,
    size: String,
    firstClickIncreaseNumbers: Boolean,
    secondClickIncreaseNumbers: Boolean,
    keyIndex: Number,
  },

  data() {
    return {
      isstudent: false,
      showBack: false,
      confirmClickNextPage: false,
      clickOnBackSide: false,
    };
  },
  watch: {
    showBack(val) {
      if (!this.isstudent) {
        const data = {
          clickType: 20014,
          data: {
            value: {
              isOpen: val,
              index: this.keyIndex,
            },
          },
          text: "speechBubble按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      }
    },
  },
  mounted() {
    this.$bus.$on("clickSpeechBubble", (val) => {
      if (val.index === this.keyIndex) {
        this.showBack = true;
        this.isstudent = true;
      }
      // this.showBack = false;
      // this.clickBubble()
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickSpeechBubble");
    // this.showBack = false;
  },
  methods: {
    clickBubble() {
      this.isstudent = false;
      // if (this.showBack == true && this.clickOnBackSide == false) {
      //   this.showBack = true;
      //   this.clickOnBackSide = true;
      // } else

      // sometimes, there are more than one speech bubble on the page. We don't want to trigger Numbers + 1 when clicking twice on a bubble
      if (this.firstClickIncreaseNumbers == true && this.showBack == false) {
        this.$emit("clickBubbleBackSide");
        this.showBack = true;
      } else if (
        this.secondClickIncreaseNumbers == true &&
        this.showBack == true
      ) {
        this.$emit("clickBubbleBackSide");
      }
      if (this.showBack == true && this.clickOnBackSide == false) {
        this.clickOnBackSide = true;
      } else if (this.showBack == false) {
        this.showBack = true;
      }
    },
  },
};
</script>
<style lang="scss">
.speech-bubble-wrapper {
  width: auto;
  position: absolute;
  top: 6%;
  left: 50%;
  cursor: pointer;

  img {
    width: 100px;

    @media (max-width: 1700px) {
      width: 80px;
    }
  }
  .conter-picture-left-word {
    position: absolute;
    top: 10%;
    // left: 37%;
    text-align: center;
    @media (max-width: 1700px) {
      top: 6%;
    }

    span {
      font-size: 40px;
      color: #fff;
      font-weight: 900;

      &.question {
        font-family: "GenSenRoundedTW";
      }
    }
  }
}
</style>
<style scoped lang="scss">
.dialog-bubble-speech {
  &.size_small,
  &.size_small img {
    // very large screen
    width: 160px;
    @media screen and (max-width: 2200px) {
      // external screen
      width: 155px;
    }
    @media screen and (max-width: 1500px) {
      // laptop
      width: 145px;
    }
    @media screen and (max-width: 1300px) {
      // ipad
      width: 90px;
    }
  }
  &.size_medium,
  &.size_medium img {
    width: 215px;
  }
  &.size_large,
  &.size_large img {
    width: 380px;
  }

  .dialog-bubble-speech__content {
    width: 100%;
    // width: min-content;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    bottom: 20%;

    @media screen and (max-width: 2200px) {
      // external screen
      bottom: 23%;
    }
    @media screen and (max-width: 1300px) {
      // ipad
      bottom: 25%;
    }
  }
}
.dialog-bubble__img {
  //   width: 100% !important;
  width: min-content;
}
.wordWrapper {
  display: flex;
  flex-direction: column;
  float: left;
  font-family: "GenSenRoundedTW";
  color: #fff;
  font-size: 40px;
  margin-right: 7px;
  text-align: center;
  margin-right: 0px;

  .txt-py {
    // very large screen
    // font-size: 35px;
    line-height: 30px;
    margin-bottom: 15px;
    font-weight: 400;
    font-family: "STHeitiSCPinyin-Light";
    @media screen and (max-width: 2200px) {
      // external screen
      // font-size: 23px;
      margin-bottom: 5px;
    }
    @media screen and (max-width: 1500px) {
      // laptop
      // font-size: 23px;
    }
    @media screen and (max-width: 1300px) {
      // ipad
      // font-size: 15px;
      margin-bottom: 0px;
    }
  }
  .txt-hz {
    // very large screen
    // font-size: 40px !important;
    line-height: 30px;
    text-align: center;
    font-weight: 400;
    font-family: "GenSenRoundedTW";
    @media screen and (max-width: 2200px) {
      // external screen
      // font-size: 35px !important;
    }
    @media screen and (max-width: 1500px) {
      // laptop
      // font-size: 35px !important;
    }
    @media screen and (max-width: 1300px) {
      // ipad
      // font-size: 20px !important;
    }
  }
}
.question {
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 80px;
  @media screen and (max-width: 2000px) {
    font-size: 60px;
  }
  @media screen and (max-width: 1300px) {
    font-size: 35px;
  }
}
.answer,
.question {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  &.question_single.font-hanzi-medium {
    font-weight: 900;
  }
  //   opacity: 0;
}
//  fade in animation for transition
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadein {
  from {
    opacity: 0 !important;
  }
  to {
    opacity: 1 !important;
    color: blue;
  }
}

.fadein {
  color: white;
  -webkit-animation: fadein 0.5s ease-in forwards;
  -moz-animation: fadein 0.5s ease-in forwards;
  animation: fadein 0.5s ease-in forwards;
}

.fadeout {
  color: white;
  -webkit-animation: fadeout 0.5s ease-in forwards;
  -moz-animation: fadeout 0.5s ease-in forwards;
  animation: fadeout 0.5s ease-in forwards;
}
</style>
