var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "practice-page-three-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "game-content" },
        [
          _c("Star", {
            attrs: { totalStarNumber: 9, currentIndex: _vm.starNo },
          }),
          _c("div", { staticClass: "word-content" }, [
            _c("div", { staticClass: "word-item-area" }, [
              _c(
                "div",
                {
                  staticClass: "word-item-wo",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.checkClick(0)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "pinyin font-pinyin-medium" }, [
                    _vm._v("wǒ"),
                  ]),
                  _c("span", { staticClass: "font-hanzi-medium" }, [
                    _vm._v("我"),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "word-item-area" }, [
              _c(
                "div",
                {
                  staticClass: "word-item-ni",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.checkClick(1)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "pinyin font-pinyin-medium" }, [
                    _vm._v("nǐ"),
                  ]),
                  _c("span", { staticClass: "font-hanzi-medium" }, [
                    _vm._v("你"),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "select-results" }, [
            _c("img", {
              staticClass: "bg-img",
              attrs: {
                src: require("@/assets/img/16-GAMES/G03-mirror/mirror-background.svg"),
                alt: "",
              },
            }),
            _c(
              "div",
              { staticClass: "mirror-img" },
              [
                _vm._l(_vm.mirrorList, function (item, index) {
                  return [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentImgIndex === index,
                          expression: "currentImgIndex === index",
                        },
                      ],
                      key: index,
                      attrs: { src: item.imgSrc, alt: "" },
                    }),
                  ]
                }),
              ],
              2
            ),
            _vm.rightGif === 1
              ? _c("div", { staticClass: "lesson-correct" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/05-Panda/panda-correct.gif"),
                      alt: "",
                    },
                  }),
                ])
              : _vm._e(),
            _vm.rightGif === 0
              ? _c("div", { staticClass: "lesson-error" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/05-Panda/panda-wrong.gif"),
                      alt: "",
                    },
                  }),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }