<template>
  <div class="game-container">
    <GuideGame :cardList="cardList" :isGuidePage="true" @showCardItem="showCardItem"/>
  </div>
</template>

<script>
import GuideGame from "@/components/Course/GamePage/GuideGame";
export default {
  data() {
    return {
      cardList: [
        {
          bgImg: require(`@/assets/img/05-Panda/page-01-speak.svg`),
          hoverImg: require(`@/assets/img/05-Panda/page-01-speak-hover.svg`),
          pinyin:"shuō",
          hanzi:"说",
          english:"speak",
          bgColorType:0,
          isShowAnswer:false,
        },
        {
          bgImg: require(`@/assets/img/05-Panda/page-01-look.svg`),
          hoverImg: require(`@/assets/img/05-Panda/page-01-look-hover.svg`),
          pinyin:"kàn",
          hanzi:"看",
          english:"read",
          bgColorType:1,
          isShowAnswer:false,
        },
        {
          bgImg: require(`@/assets/img/05-Panda/page-01-listen.svg`),
          hoverImg: require(`@/assets/img/05-Panda/page-01-listen-hover.svg`),
          pinyin:"tīng",
          hanzi:"听",
          english:"listen",
          bgColorType:0,
          isShowAnswer:false,
        },
      ],
    };
  },
  components: {
    GuideGame,
  },
  methods:{
    showCardItem(index){
      this.cardList[index].isShowAnswer = !this.cardList[index].isShowAnswer
    }
  }
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











