<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-1.mp3"),
      },
      timeStampList: [8.2, 12.5, 16.9, 21, 25],

      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì Dōngdōng.",
            chineseWords: "你好！你好！我是冬冬。",
          },
          {
            id: 2,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì Xiǎo'ài.",
            chineseWords: "你好！你好！我是小爱。",
          },
          {
            id: 3,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì BB.",
            chineseWords: "你好！你好！我是BB。",
          },
          {
            id: 4,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì ...",
            chineseWords: "你好！你好！我是……",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
