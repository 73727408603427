<template>
  <div class="story-page-two-container" @click="handleClick">
    <StoryPage :bgImg="bgImg_1" v-if="currentIndex === 0" />
    <StoryPage :bgImg="bgImg_2" v-if="currentIndex === 1" />
  </div>
</template>

<script>
import StoryPage from "@/components/Course/CoursePage/StoryPage";
export default {
  name: "StoryPageTwo",
  components: {
    StoryPage,
  },
  data() {
    return {
      currentIndex: 0,
      bgImg_1: require("@/assets/img/13-Video/chapter-01/lesson-1-video-2-1.svg"),
      bgImg_2: require("@/assets/img/13-Video/chapter-01/lesson-1-video-2-2.svg"),
    };
  },
  methods: {
    handleClick() {
      if (this.currentIndex >= 1) {
        this.currentIndex = 1;
        return;
      }
      this.currentIndex++;
    },
  },
};
</script>

<style lang="scss" scoped>
.story-page-two-container {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>