<template>
  <div class="practice-page-one-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :numBers="numBers"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
      @updateNumbers="updateNumbers($event)"
    ></PageButton>
    <div class="customlesson_content">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="numBers"
        @changeTheNumbers="changeTheNumbers"
      >
      </SceneSwitchThumbnails>
      <div
        class="customlesson-conter-picture-switching story-one"
        v-if="numBers === 1 || numBers === 2"
      >
        <div class="conter-picture">
          <img src="@/assets/img/16-GAMES/G01-hi/background-ball.svg" alt="" />
          <div class="conter-picture-right">
            <img src="@/assets/img/16-GAMES/G01-hi/lesson-01-work.svg" alt="" />
            <div class="speech-bubble-1 speech-bubble-wrapper">
              <Speechbubble
                :question="`?`"
                :answer="[
                  { py: 'Nǐ', hz: '你' },
                  { py: 'hǎo', hz: '好' },
                ]"
                :size="`small`"
                @clickBubbleBackSide="numClick(numBers)"
                :firstClickIncreaseNumbers="true"
                :secondClickIncreaseNumbers="true"
                :key="1"
                :keyIndex="1"
              ></Speechbubble>
            </div>
          </div>
        </div>
      </div>

      <!-- step 1 on story 2 -->
      <div
        class="customlesson-conter-picture-switching story-two"
        v-if="numBers >= 3 && numBers <= 9"
      >
        <div class="conter-picture">
          <img src="@/assets/img/16-GAMES/G01-hi/background-ball.svg" alt="" />
          <div class="conter-picture-right">
            <img src="@/assets/img/16-GAMES/G01-hi/lesson-01-bus.svg" alt="" />
            <div class="speech-bubble-1 speech-bubble-wrapper">
              <Speechbubble
                :question="`?`"
                :answer="[
                  { py: 'Nǐ', hz: '你' },
                  { py: 'hǎo', hz: '好' },
                ]"
                :size="`small`"
                @clickBubbleBackSide="numClick(numBers)"
                :firstClickIncreaseNumbers="true"
                :key="2"
                :keyIndex="2"
              ></Speechbubble>
            </div>
            <div class="speech-bubble-2 speech-bubble-wrapper">
              <Speechbubble
                :question="`?`"
                :answer="[
                  { py: 'Nǐ', hz: '你' },
                  { py: 'hǎo', hz: '好' },
                ]"
                :size="`small`"
                @clickBubbleBackSide="numClick(numBers)"
                :firstClickIncreaseNumbers="true"
                :key="3"
                :keyIndex="3"
              ></Speechbubble>
            </div>
            <div class="speech-bubble-3 speech-bubble-wrapper">
              <Speechbubble
                :question="`?`"
                :answer="[
                  { py: 'Nǐ', hz: '你' },
                  { py: 'hǎo', hz: '好' },
                ]"
                :size="`small`"
                @clickBubbleBackSide="numClick(numBers)"
                :firstClickIncreaseNumbers="true"
                :key="4"
                :keyIndex="4"
              ></Speechbubble>
            </div>
            <div class="speech-bubble-4 speech-bubble-wrapper">
              <Speechbubble
                :question="`?`"
                :answer="[
                  { py: 'Nǐ', hz: '你' },
                  { py: 'hǎo', hz: '好' },
                ]"
                :size="`small`"
                @clickBubbleBackSide="numClick(numBers)"
                :firstClickIncreaseNumbers="true"
                :key="5"
                :keyIndex="5"
              ></Speechbubble>
            </div>
          </div>
        </div>
      </div>

      <!-- step 2 on story 2 -->

      <!-- step 4 on story 2 -->

      <!-- step 5 on story 2 -->
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import Speechbubble from "@/components/Course/CoursePage/SpeechBubble/index.vue";

export default {
  components: {
    SceneSwitchThumbnails,
    PageButton,
    Speechbubble,
  },
  data() {
    return {
      numBers: 1,
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G01-hi/menu-bus.svg"),
          startNumberRange: 1,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G01-hi/menu-man.svg"),
          startNumberRange: 3,
          endNumberRange: 7,
        },
      ],
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 1,
            endNumberRange: 6,
            numBerValueAfterClick: null,
          },
        ],
      },
      isLastStep: false,
      numClickValue: false,
    };
  },
  watch: {
    numBers(value) {
      // 测试socket
      // console.log(value);
      if (!this.numClickValue) {
        const data = {
          clickType: 30002,
          data: { value },
          text: "practicePage页面按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      }
    },
  },
  mounted() {
    this.$bus.$on("clickPractOneSteps", (val) => {
      this.numClick(val - 1, 1);
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickPractOneSteps");
  },
  methods: {
    changeTheNumbers(index) {
      this.numBers = index;
    },
    numClick(type, stype) {
      if (stype != 1) {
        this.numClickValue = false;
      } else {
        this.numClickValue = true;
      }
      this.numBers = type + 1;
      if (this.numBers == 2) {
        playCorrectSound(true, false);
      } else if (this.numBers == 7) {
        this.isLastStep = true;
        playCorrectSound();

        startConfetti();
      } else {
        return;
      }
    },
    updateNumbers(value) {
      this.numBers = value;
      console.log(value);
      if (value == 7) {
        this.$bus.$emit("nextButton", true);
      }
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
  },
};
</script>
<style scoped lang="scss">
.practice-page-one-container {
  .customlesson_content {
    width: 100%;
    height: 100%;
    background-image: url("../../../../../assets/img/03-Backgrounds/level-1-background-blue.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #edf2f9;
    border-radius: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    .customlesson_bottom_left_img_two {
      position: absolute;
      bottom: -1%;
      right: 0;
      width: 24%;
      border-top-left-radius: 58px;
      text-align: center;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    .customlesson_bottom_left_img_one {
      position: absolute;
      bottom: -5%;
      right: -2%;
      width: 18%;
      border-top-left-radius: 58px;
      text-align: center;
      cursor: pointer;
      img {
        width: 90%;
      }
    }
    .customlesson_content-left-small-picture {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      width: 8%;
      background-color: #d87065;
      border-top-right-radius: 52px;
      border-bottom-left-radius: 52px;
      border-bottom: 8px solid #a53d32;
      .small-picture-content {
        margin-top: 10px;
        background-color: #cd4c3f;
        border-top-right-radius: 52px;
        border-bottom-left-radius: 46px;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        .small-picture-content-one {
          margin-top: 30px;
          width: 60%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          img {
            width: 100%;
            cursor: pointer;
          }
        }
        .small-picture-content-one:nth-child(2) {
          margin-bottom: 20px;
          padding: 6px;
          width: 60%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          img {
            width: 100%;
          }
        }
      }
    }
    // .customlesson_content-left-small-picture:hover{
    //   .small-picture-content{
    //       background-color:#A53D32 ;
    //       border-bottom:8px solid #A53D32;
    //     }
    // }
    .customlesson-conter-picture-switching {
      width: 50%;
      .conter-picture {
        width: 100%;
        position: absolute;
        top: 40%;
        left: 10%;
        display: flex;
        img {
          width: 35%;
        }
        .conter-picture-right {
          width: 50%;
          bottom: 5%;

          @media screen and (max-width: 1600px) {
            width: 60%;
          }

          @media screen and (max-height: 900px) {
            // ipad
            // top: 40%;
            img {
              max-height: 70vh;
            }
          }

          position: absolute;
          bottom: 10%;
          left: 15%;
          cursor: default;
          img {
            width: 100%;
          }
          .conter-picture-left {
            width: 20%;
            position: absolute;
            top: -10%;
            left: 35%;
            img {
              width: 60%;
            }
            .conter-picture-left-word {
              position: absolute;
              span {
                font-size: 40px;
                color: #fff;
                font-weight: 400;
              }
            }
          }
          .conter-picture-right-heartbeat-one {
            width: 20%;
            position: absolute;
            top: 0%;
            left: 30%;
            img {
              width: 60%;
            }
            .conter-picture-left-word {
              position: absolute;
              top: 8%;
              left: 20%;
              span {
                font-size: 40px;
                color: #fff;
                font-weight: 400;
              }
            }
          }
          .conter-picture-right-heartbeat-two {
            width: 20%;
            position: absolute;
            top: 20%;
            left: 50%;
            img {
              width: 60%;
            }
            .conter-picture-left-word {
              position: absolute;
              // top: 5%;
              left: 20%;
              span {
                font-size: 40px;
                color: #fff;
                font-weight: 900;
              }
            }
          }
          .conter-picture-right-heartbeat-three {
            width: 20%;
            position: absolute;
            top: 20%;
            left: 70%;
            img {
              width: 60%;
            }
            .conter-picture-left-word {
              position: absolute;
              // top: 5%;
              left: 20%;
              span {
                font-size: 40px;
                color: #fff;
                font-weight: 900;
              }
            }
          }
          .conter-picture-right-heartbeat-four {
            width: 20%;
            position: absolute;
            top: 20%;
            left: 90%;
            img {
              width: 60%;
            }
            .conter-picture-left-word {
              position: absolute;
              // top: 5%;
              left: 20%;
              span {
                font-size: 40px;
                color: #fff;
                font-weight: 900;
              }
            }
          }
          .conter-picture-right-heartbeat {
            width: 20%;
            position: absolute;
            top: -15%;
            left: 35%;
            img {
              width: 100%;
            }
            .conter-picture-left-word {
              position: absolute;
              display: flex;
              flex-flow: column;
              justify-content: center;
              align-items: center;
              span {
                font-size: 30px;
                color: #fff;
              }
            }
          }
          .conter-picture-right-heartbeate {
            width: 20%;
            position: absolute;
            top: -5%;
            left: 30%;
            img {
              width: 100%;
            }
            .conter-picture-left-word {
              position: absolute;
              top: 12%;
              left: 0;
              width: 100%;
              display: flex;
              flex-flow: column;
              justify-content: center;
              align-items: center;
              span {
                font-size: 30px;
                color: #fff;
              }
            }
          }
          .lesson_treeImg {
            width: 30%;
            position: absolute;
            top: -30%;
            right: 45%;
            img {
              width: 100%;
            }
          }
          .dialog-bubble {
            width: auto;
          }
        }
      }
      &.story-one {
        .conter-picture {
          @media (max-width: 1700px) {
            left: 0;
            top: 48%;
          }
        }
        .dialog-bubble-answer {
          > div {
            width: 100%;
            left: 0;
          }
        }
        .dialog-bubble-answer {
          > div {
            width: 100%;
            left: 0;
          }

          img {
            // very large screen
            width: 200px !important;
            @media screen and (max-width: 2200px) {
              // external screen
              width: 150px !important;
            }
            @media screen and (max-width: 1500px) {
              // laptop
              width: 135px !important;
            }
            @media screen and (max-width: 1300px) {
              // ipad
              width: 105px !important;
            }
          }

          span {
            // very large screen
            font-size: 40px;
            @media screen and (max-width: 2200px) {
              // external screen
              font-size: 30px;
            }
            @media screen and (max-width: 1500px) {
              // laptop
              font-size: 30px;
            }
            @media screen and (max-width: 1300px) {
              // ipad
              font-size: 20px;
            }
          }
        }
      }
      &.story-two {
        .conter-picture {
          @media (max-width: 1700px) {
            left: 0;
          }
        }
        .dialog-bubble-answer,
        .dialog-bubble-question {
          > div {
            width: 100%;
            left: 0 !important;
          }
        }
        .dialog-bubble-answer {
          > div {
            width: 100%;
            left: 0;
            // very large screen

            @media screen and (max-width: 2200px) {
              // external screen
            }
            @media screen and (max-width: 1500px) {
              // laptop
            }
            @media screen and (max-width: 1300px) {
              // ipad
              top: 5% !important;
            }
          }

          img {
            // very large screen
            width: 200px !important;
            @media screen and (max-width: 2200px) {
              // external screen
              width: 150px !important;
            }
            @media screen and (max-width: 1500px) {
              // laptop
              width: 135px !important;
            }
            @media screen and (max-width: 1300px) {
              // ipad
              width: 105px !important;
            }
          }

          span {
            // very large screen
            font-size: 40px;
            @media screen and (max-width: 2200px) {
              // external screen
              font-size: 30px;
            }
            @media screen and (max-width: 1500px) {
              // laptop
              font-size: 30px;
            }
            @media screen and (max-width: 1300px) {
              // ipad
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  .confettiCanvas {
    z-index: 900;
    position: absolute;
  }
  .story-one {
    .conter-picture-left-word {
      &.answer {
        top: 11%;
        left: 15%;
        @media screen and (max-width: 2200px) {
          // external screen
          top: 7%;
        }
      }

      .txt-py {
        @media screen and (max-width: 2200px) {
          // external screen
          font-size: 1.4em !important;
        }
        @media screen and (max-width: 1500px) {
          // laptop
          font-size: 1em !important;
        }
      }
      .txt-hz {
        font-size: 3em !important;
        @media screen and (max-width: 2200px) {
          // external screen
          font-size: 2.4em !important;
        }
        @media screen and (max-width: 1500px) {
          // laptop
          font-size: 2em !important;
        }
        @media screen and (max-width: 1300px) {
          // ipad
          font-size: 1.6em !important;
        }
      }
    }
  }

  .story-two {
    .conter-picture-left-word {
      &.answer {
        top: 11%;
        left: 15%;
        @media screen and (max-width: 2200px) {
          // external screen
          top: 7%;
        }
      }

      .txt-py {
        @media screen and (max-width: 2200px) {
          // external screen
          font-size: 1.4em !important;
        }
        @media screen and (max-width: 1500px) {
          // laptop
          font-size: 1em !important;
        }
        @media screen and (max-width: 1300px) {
          // ipad
          font-size: 0.8em !important;
        }
      }
      .txt-hz {
        font-size: 3em !important;
        @media screen and (max-width: 2200px) {
          // external screen
          font-size: 2.4em !important;
        }
        @media screen and (max-width: 1500px) {
          // laptop
          font-size: 2em !important;
        }
        @media screen and (max-width: 1300px) {
          // ipad
          font-size: 1.6em !important;
        }
      }
    }
  }

  .dialog-bubble-answer {
    // very large screen
    width: 200px !important;
    @media screen and (max-width: 2200px) {
      // external screen
      width: 150px !important;
    }
    @media screen and (max-width: 1500px) {
      // laptop
      width: 135px !important;
    }
    @media screen and (max-width: 1300px) {
      // ipad
      width: 105px !important;
    }

    &.answer-driver {
    }
    &.answer-kids-left {
      top: 15% !important;
      left: 47% !important;
      z-index: 10;

      // very large screen

      @media screen and (max-width: 2200px) {
        // external screen
        width: 150px !important;
      }
      @media screen and (max-width: 1500px) {
        // laptop
      }
      @media screen and (max-width: 1300px) {
        // ipad
        width: 105px !important;
      }
    }
    &.answer-kids-center {
      top: 15% !important;
      left: 72% !important;
      z-index: 11;
    }
    &.answer-kids-right {
      top: 15% !important;
      left: 97% !important;
      z-index: 12;
    }
  }
}

.speech-bubble-1 {
  top: -14%;
  left: 35%;
}

.speech-bubble-2 {
  top: 15%;
  left: 44%;
}
.speech-bubble-3 {
  top: 15%;
  left: 69%;
}
.speech-bubble-4 {
  top: 15%;
  left: 94%;
}
</style>
