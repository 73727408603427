var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "introdution-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
          numBers: _vm.numBers,
        },
        on: {
          clickNext: _vm.nextPage,
          updateNumbers: function ($event) {
            return _vm.updateNumbers($event)
          },
        },
      }),
      _c(
        "div",
        { staticClass: "introdution-content" },
        [
          _c("SceneSwitchThumbnails", {
            attrs: { buttons: _vm.buttonList, numBers: _vm.numBers },
            on: { changeTheNumbers: _vm.changeTheNumbers },
          }),
          _vm.numBers === 1 || _vm.numBers === 2
            ? _c(
                "div",
                {
                  staticClass:
                    "customlesson-conter-picture-switching story-one",
                },
                [
                  _c("div", { staticClass: "conter-picture" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/16-GAMES/G01-hi/background-ball.svg"),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "conter-picture-right" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/16-GAMES/G01-hi/lesson-01-astronaut.svg"),
                          alt: "",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "speech-bubble-1" },
                        [
                          _c("Speechbubble", {
                            key: 1,
                            attrs: {
                              question: `?`,
                              answer: [
                                { py: "Nǐ", hz: "你" },
                                { py: "hǎo", hz: "好" },
                              ],
                              size: `small`,
                              secondClickIncreaseNumbers: true,
                              firstClickIncreaseNumbers: true,
                            },
                            on: {
                              clickBubbleBackSide: function ($event) {
                                return _vm.numClick(_vm.numBers)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.numBers === 3 || _vm.numBers === 4 || _vm.numBers === 5
            ? _c(
                "div",
                {
                  staticClass:
                    "customlesson-conter-picture-switching story-two",
                },
                [
                  _c("div", { staticClass: "conter-picture" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/16-GAMES/G01-hi/background-ball.svg"),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "conter-picture-right" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/16-GAMES/G01-hi/lesson-01-dog.svg"),
                          alt: "",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "speech-bubble-2" },
                        [
                          _c("Speechbubble", {
                            key: 2,
                            attrs: {
                              question: `?`,
                              answer: [
                                { py: "Nǐ", hz: "你" },
                                { py: "hǎo", hz: "好" },
                              ],
                              size: `small`,
                              firstClickIncreaseNumbers: true,
                              secondClickIncreaseNumbers: true,
                            },
                            on: {
                              clickBubbleBackSide: function ($event) {
                                return _vm.numClick(_vm.numBers)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }