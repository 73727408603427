var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "story-page-three-container",
      on: { click: _vm.handleClick },
    },
    [
      _vm.currentIndex === 0
        ? _c("StoryPage", { attrs: { bgImg: _vm.bgImg_1 } })
        : _vm._e(),
      _vm.currentIndex === 1
        ? _c("StoryPage", { attrs: { bgImg: _vm.bgImg_2 } })
        : _vm._e(),
      _vm.currentIndex === 2
        ? _c("StoryPage", { attrs: { bgImg: _vm.bgImg_3 } })
        : _vm._e(),
      _vm.currentIndex === 3
        ? _c("StoryPage", { attrs: { bgImg: _vm.bgImg_4 } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }