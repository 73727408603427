var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "practice-page-four-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        {
          staticClass: "finger",
          on: {
            click: function ($event) {
              return _vm.numClick()
            },
          },
        },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/img/17-Tutorials/instructions.svg"),
              alt: "",
            },
          }),
        ]
      ),
      _c(
        "div",
        { staticClass: "practice-page-four-content" },
        [
          _vm.numBers === 1 || _vm.numBers === 2
            ? _c(
                "div",
                { staticClass: "customlesson-picture-switching-right" },
                [
                  _vm.numBers === 1
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/img/16-GAMES/G04-photo-studio/photo-dongdong-filter.svg"),
                          alt: "",
                        },
                      })
                    : _vm._e(),
                  _vm.numBers === 2
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/img/16-GAMES/G04-photo-studio/photo-dongdong.svg"),
                          alt: "",
                        },
                      })
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm.numBers === 3 || _vm.numBers === 4
            ? _c(
                "div",
                { staticClass: "customlesson-picture-switching-left" },
                [
                  _vm.numBers === 3
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/img/16-GAMES/G04-photo-studio/photo-xiaoai-filter.svg"),
                          alt: "",
                        },
                      })
                    : _vm._e(),
                  _vm.numBers === 4
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/img/16-GAMES/G04-photo-studio/photo-xiaoai.svg"),
                          alt: "",
                        },
                      })
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _c("SceneSwitchThumbnails", {
            attrs: { buttons: _vm.buttonList, numBers: _vm.numBers },
            on: { changeTheNumbers: _vm.changeTheNumbers },
          }),
          _vm._m(0),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "customlesson-conter-right-text" }, [
      _c("div", { staticClass: "right-text" }, [
        _c("span", { staticClass: "font-text txt-py font-pinyin-medium" }, [
          _vm._v("shì"),
        ]),
        _c("span", { staticClass: "txt-hz font-hanzi-medium" }, [
          _vm._v("是....."),
        ]),
      ]),
      _c("div", { staticClass: "camera" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/img/16-GAMES/G04-photo-studio/camera.svg"),
            alt: "",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }